<template>
  <v-container fluid class="ma-0 pa-0" :class="{'light-background': $vuetify.breakpoint.xsOnly, 'white': $vuetify.breakpoint.smAndUp}">
    <v-layout row wrap :pt-5="$vuetify.breakpoint.smAndUp" :px-5="$vuetify.breakpoint.smAndUp">
      <v-flex xs12 max-width-1400 full-width ma-auto>
        <v-layout row wrap>
          <v-flex xs12>
            <v-card flat tile color="transparent">
              <v-card-text class="light-background">
                <v-form v-model="valid_search" class="full-height">
                  <v-container fluid class="pa-0 ma-0" grid-list-xl>
                    <v-layout row wrap>
                      <v-flex xs12 sm4>
                        <p class="graphik-medium font-17 mb-2 dark-grey--text">{{ $ml.get('fields_last_name') }}</p>

                        <v-text-field
                          v-model="search.last_name"
                          solo
                          clearable
                          required
                          :disabled="g_isLoading"
                          :rules="g_required_rules"
                          class="hidden-shadow small-input"
                        ></v-text-field>
                      </v-flex>

                      <v-flex xs12 sm4>
                        <p class="graphik-medium font-17 mb-2 dark-grey--text">{{ $ml.get('fields_policy_number') }}</p>

                        <v-text-field
                          v-model="search.policy"
                          solo
                          clearable
                          required
                          :disabled="g_isLoading"
                          :rules="g_required_rules"
                          class="hidden-shadow small-input"
                        ></v-text-field>
                      </v-flex>

                      <v-flex xs12 sm4>
                        <p class="graphik-medium font-17 mb-2 dark-grey--text">{{ $ml.get('fields_company') }}</p>

                        <v-autocomplete
                          v-model="search.insurance"
                          :items="options.companies"
                          solo
                          clearable
                          :disabled="g_isLoading"
                          required
                          :rules="g_required_rules"
                          :no-data-text="$ml.get('general_no_results')"
                          class="hidden-shadow small-input"
                          ref="car_model"
                        ></v-autocomplete>
                      </v-flex>
                    </v-layout>
                  </v-container>
                </v-form>
              </v-card-text>

              <v-card-actions class="pa-3 light-background">
                <v-spacer></v-spacer>
                <v-btn 
                  color="pantene"
                  depressed
                  :loading="g_isLoading"
                  class="normalcase graphik-bold-italic mx-0 mb-4 border-radius-5 white--text"
                  :disabled="g_isLoading || !valid_search"
                  @click="searchPolicy"
                >{{ $ml.get('general_view_policy') }}</v-btn>
              </v-card-actions>

              <v-img
                max-width="450px"
                contain
                :src="image"
                :lazy-src="image"
              />
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>

    <message-dialog :dialog="g_dialog"></message-dialog>
    <loading-dialog :isLoading="g_isLoading"></loading-dialog>
  </v-container>
</template>

<script>
export default {
  name: 'Policy',
  metaInfo: {
    title: process.env.VUE_APP_METATAGS_TITLE_POLICY_SEARCH,
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: process.env.VUE_APP_METATAGS_DESCRIPTION_POLICY_SEARCH
      },
      {
        vmid: 'og:title',
        property: 'og:title',
        content: process.env.VUE_APP_METATAGS_TITLE_POLICY_SEARCH
      },
      {
        vmid: 'og:description',
        property: 'og:description',
        content: process.env.VUE_APP_METATAGS_DESCRIPTION_POLICY_SEARCH
      },
      {
        vmid: 'twitter:title',
        property: 'twitter:title',
        content: process.env.VUE_APP_METATAGS_TITLE_POLICY_SEARCH
      },
      {
        vmid: 'twitter:description',
        property: 'twitter:description',
        content: process.env.VUE_APP_METATAGS_DESCRIPTION_POLICY_SEARCH
      },
      {
        vmid: 'og:url',
        property: 'og:url',
        content: process.env.VUE_APP_METATAGS_URL + '/buscar/poliza'
      },
    ],
    link: [
      // SEO
      {
        vmid: 'canonical',
        rel: 'canonical',
        href: process.env.VUE_APP_METATAGS_URL + '/buscar/poliza'
      }
    ]
  },
  data () {
    return {
      valid_search: false,
      search: {
        last_name: '',
        policy: '',
        insurance: ''
      },
      options: {
        companies: [
          {
            text: this.$ml.get('insurance_ana'),
            value: 'ana'
          },
          {
            text: this.$ml.get('insurance_axa'),
            value: 'axa'
          },
          {
            text: this.$ml.get('insurance_chubb'),
            value: 'chubb'
          },
          {
            text: this.$ml.get('insurance_gnp'),
            value: 'gnp'
          },
          {
            text: this.$ml.get('insurance_primeroSeguros'),
            value: 'primeroSeguros'
          },
          {
            text: this.$ml.get('insurance_qualitas'),
            value: 'qualitas'
          },
          {
            text: this.$ml.get('insurance_afirme'),
            value: 'afirme'
          },
          {
            text: this.$ml.get('insurance_hdi'),
            value: 'hdi'
          },
          {
            text: this.$ml.get('insurance_mapfre'),
            value: 'mapfre'
          }
        ]
      },
      image: require('@/assets/img/images/search_policy.png'),
    }
  },
  methods: {
    // CRUD
    searchPolicy () {
      this.g_isLoading = true
      this.$api.searchPolicy(
        this.search,
        response => {
          this.$goTo('/poliza/' + response.body.data.uuid, 'goto_view_policy')
          this.g_isLoading = false
        },
        response => this.$errorHandling(response).then(() => {
          this.searchPolicy()
        }, () => {})
      )
    }
  }
}
</script>

<style scoped>

</style>
